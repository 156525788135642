@import "variables";

.be-dark-mode {

  // fix scrollbars on chrome
  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent; // TODO: add color for track maybe
  }

  ::-webkit-scrollbar-thumb {
    background-color: mat-color($app-foreground, divider);
    border-radius: 3px;

    &:hover {
      background-color: mat-color($app-foreground, divider);
    }
  }

  // make input borders thicker so they are easier
  // to see as input bg will be transparent usually
  .input-container {
    input, select, textarea, .fake-input {
      border-width: 1.5px;
    }
  }

  // make material slide toggle thumb darker
  .mat-slide-toggle-thumb {
    background-color: #bdbdbd;
  }

  // fix native select dropdown color
  select, option {
    background-color: mat-color($app-background, card);
    color: mat-color($app-foreground, text);
  }

  // invert material snackbar colors
  .mat-snack-bar-container {
    background-color: #fafafa;
    color: rgba(0,0,0,0.87);
  }

  .mat-simple-snackbar-action {
    color: inherit;
  }

  // fix mat checkbox and pseudo checkbox disabled colors
  .mat-pseudo-checkbox-disabled {
    color: #686868;
  }

  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
    background-color: #686868;
  }

  .mat-checkbox-disabled {
    &.mat-checkbox-checked,
    &.mat-checkbox-indeterminate {
      .mat-checkbox-background {
        background-color: #686868;
      }
    }

    &:not(.mat-checkbox-checked) {
      .mat-checkbox-frame {
        border-color: #686868;
      }
    }
  }

  // fix material button toggle checked color on disabled toggle
  .mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: #424242;
  }

  // material navbar should be dark as well instead of using primary color
  material-navbar:not(.transparent) {
    background-color: mat-color($app-background, card);
  }
}